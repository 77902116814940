import React from "react";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../../../redux/slices/ui.slice";
import { useGetAllProductCategoriesQuery } from "../../../../../services/e-shop.service";
import { DELETE_PRODUCT_CATEGORIES } from "../../../../../services/CONSTANTS";
import { Tooltip } from "antd";

const CategoryList = ({ onEdit }) => {
    const dispatch = useDispatch();

    // Fetch product categories
    const { data, isFetching, refetch } = useGetAllProductCategoriesQuery();
    const categories = data?.data || [];

    // Handle delete confirmation
    const doDelete = (item) => {
        dispatch(
            toggleDelete({
                open: true,
                title: "Product Category",
                url: `${DELETE_PRODUCT_CATEGORIES}?category_id=${item?.id}`,
                parent: "tr"
            })
        );
    };

    return (
        <div className="card">
            <div className="card-header">Categories</div>
            <div className="card-body">
                <button onClick={refetch} className="btn btn-primary mb-3">
                    Refresh Categories
                </button>
                <div className="table-responsive">
                    <table className="table table-striped table-vcenter table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Category Name</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isFetching ? (
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        <div className="loader" />
                                    </td>
                                </tr>
                            ) : categories.length > 0 ? (
                                categories.map((cat) => (
                                    <tr key={cat.id}>
                                        <td>{cat.name}</td>
                                        <td>{cat.status === 1 ? "Active" : "Inactive"}</td>
                                        <td>
                                            <button
                                                type="button"
                                                className="btn btn-icon btn-sm"
                                                title="Edit"
                                                onClick={() => onEdit(cat)}
                                            >
                                                <i className="icon-pencil text-info" />
                                            </button>
                                            <Tooltip title="Delete">
                                                <button
                                                    type="button"
                                                    className="btn btn-icon btn-sm"
                                                    data-target="#deleteModal"
                                                    data-toggle="modal"
                                                    onClick={() => doDelete(cat)}
                                                >
                                                    <i className="icon-trash text-danger" />
                                                </button>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        No categories found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CategoryList;
