import apiSlice from "./api/api";
import {
    GET_ALL_PRODUCT_CATEGORIES,
    GET_PRODUCT_CATEGORY_BY_ID,
    CREATE_PRODUCT_CATEGORIES,
    UPDATE_PRODUCT_CATEGORIES,
    DELETE_PRODUCT_CATEGORIES
} from "./CONSTANTS";

const eShopApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        getAllProductCategories: builder.query({
            query: () => ({
                url: GET_ALL_PRODUCT_CATEGORIES,
                method: "GET",
            }),
            providesTags: ["E-Shop"]
        }),

        getProductCategoriesByID: builder.query({
            query: ({ cat_id }) => ({
                url: `${GET_PRODUCT_CATEGORY_BY_ID}?category_id=${cat_id}`,
                method: "GET",
            }),
            providesTags: ["E-Shop"]
        }),

        createProductCategories: builder.mutation({
            query: (data) => ({
                url: CREATE_PRODUCT_CATEGORIES,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["E-Shop"]
        }),

        updateProductCategories: builder.mutation({
            query: (data) => ({
                url: UPDATE_PRODUCT_CATEGORIES,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["E-Shop"]
        }),

        deleteProductCategories: builder.mutation({
            query: (cat_id) => ({
                url: `${DELETE_PRODUCT_CATEGORIES}?category_id=${cat_id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["E-Shop"]
        })
    })
});

export const {
    useGetAllProductCategoriesQuery,
    useGetProductCategoriesByIDQuery,
    useCreateProductCategoriesMutation,
    useUpdateProductCategoriesMutation,
    useDeleteProductCategoriesMutation, 
} = eShopApiSlice;
