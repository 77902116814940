import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useGetMemberSavingsRequestQuery } from "../../services/member.service";
import { Empty, Tooltip } from "antd";
import Card from "../common/Card";
import { formatNumber } from "../../utils/helpers";
import ApproveDenyAsset from "../common/ApproveDenyAsset";

export const SavingsRequest = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {user} = useSelector(state => state.user);
    const {activeCoop} = useSelector(state => state.coop);
    const [requests, setRequests] = useState([]);
    const [selectedItem, setSelected] = useState(null);

    const {data, isLoading} = useGetMemberSavingsRequestQuery({
        id: activeCoop?.id,
        coopId: user?.coop_id, 
        page: 1,
        role: user?.role_name
    }, {skip: !user});

    useEffect(() => {
        if (data && data.status) {
            setRequests(data.data.data)
        }
    }, [data])

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <Card title={`Savings Requests`} loading={isLoading}>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped table-vcenter table-hover mb-0">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    {user?.role_name !== 'cooperative-member' && <th>Member</th>}
                                    <th>Product</th>
                                    <th>Old Amount</th>
                                    <th>New Amount</th>
                                    <th>Status</th>
                                    {user?.role_name !== 'cooperative-member' && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                            {requests?.map((row, i) =>
                                <tr key={`saving-request-${row.id}`}>
                                    <td>{i + 1}</td>
                                    {user?.role_name != 'cooperative-member' &&  <td>{row.member.firstname + ' '+row.member.lastname}</td>}
                                    <td>{row.savings?.product?.product?.name}</td>
                                    <td><div className="font-15">{formatNumber(row.old_amount)}</div></td>
                                    <td><div className="font-15">{formatNumber(row.new_amount)}</div></td>
                                    <td>
                                        {row.status === 0 && <span className="tag tag-warning">Pending</span>}
                                        {row.status === 1 && <span className="tag tag-success">Approved</span>}
                                        {row.status === 2 && <span className="tag tag-danger">Denied</span>}
                                    </td>
                                    {user?.role_name !== 'cooperative-member' && 
                                    <td>
                                       {row.status === 0 && 
                                            <div className="btn-group btn-sm">
                                                <Tooltip title="Approve">
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-sm py-0" 
                                                        data-target="#assetModal" data-toggle="modal"
                                                        onClick={() => setSelected({id: row.id, type: 'change-request', action: 'approve'})}
                                                    >
                                                        <i className="icon-like text-info" />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Deny" >
                                                    <button type="button" 
                                                        className="btn btn-sm py-0"
                                                        data-target="#assetModal" data-toggle="modal"
                                                        onClick={() => setSelected({id: row.id, type: 'change-request', action: 'deny'})}
                                                    >
                                                        <i className="icon-dislike text-danger" />
                                                    </button>
                                                </Tooltip>
                                            </div>}
                                    </td>}

                                  
                                </tr>
                            )}
                            {requests.length === 0 && <tr><td colSpan={6}><Empty /></td></tr>}

                            </tbody>
                        </table>
                    </div>
                </div>
            </Card>
            <ApproveDenyAsset 
                id={selectedItem?.id} 
                type={selectedItem?.type} 
                action={selectedItem?.action} 
                coopId={activeCoop?.id} 
                title="Change Request"
            />
        </div>
    )
}