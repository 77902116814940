import React, { useState } from "react";
import CategoryList from "./CategoryList";
import CategoryForm from "./CategoryForm";

const ProductCategory = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);

    return (
        <div className="row">
            <div className="col-md-6 col-sm-12">
                <CategoryList onEdit={(category) => setSelectedCategory(category)} />
            </div>
            <div className="col-md-6 col-sm-12">
                <CategoryForm cat={selectedCategory} onCancel={() => setSelectedCategory(null)} />
            </div>
        </div>
    );
};

export default ProductCategory;
