import apiSlice from "./api/api";
import { 
  ADD_NEW_SAVINGS, APPLY_FOR_LOAN, APPROVE_LOAN, APPROVE_TRANSACTION_ORDER, CHANGE_MEMBER_PASSWORD, CHANGE_SAVINGS_AMOUNT, 
  CREATE_MEMBER, CREATE_TRANSACTION_ORDER, DENY_LOAN, DENY_TRANSACTION_ORDER, DOWNLOAD_LOAN_FORM, EDIT_MEMBER_DETAILS, 
  GET_ALL_DEPOSITS, GET_ALL_SAVINGS, GET_ALL_WITHDRAWALS, MEMBER_TRANSACTION_HISTORY, GET_LOAN_SCHEDULE, GET_LOAN_SURETY, 
  GET_MEMBER_SAVINGS, GET_MEMBERS, IMPORT_LOANS, IMPORT_MEMBERS, MARK_LOAN_COMPLETED, MARK_LOAN_INCOMPLETE, 
  MEMBERS_DASHBOARD, 
  GET_MEMBER,
  DOWNLOAD_SAMPLE_MEMBERS_IMPORT,
  GET_MEMBER_LOANS,
  GET_LOAN_LIST,
  GET_MEMBER_SURETY,
  GET_APPROVE_SURETY,
  GET_DENY_SURETY,
  IMPORT_SAVINGS,
  GET_OFFSET_MODE,
  GET_OFFSET_TYPE,
  GET_OFFSET_MEM_LOAN,
  GET_LOAN_OFFSET,
  GET_LOAN_DETAILS,
  LOAN_OFFSET,
  PENDING_MEMBERS,
  GET_PENDING_MEMBER,
  DENY_PENDING_MEMBER,
  APPROVE_PENDING_MEMBER,
  UPLOAD_LOAN_FORM,
  GET_MEMBER_UPLOADED_FORMS,
  GET_UPLOADED_FORMS,
  DOWNLOAD_UPLOADED_FORM,
  GET_COOPERATIVE_DETAILS,
  GET_COOPERATIVE_DEPARTMENTS,
  DEACTIVATE_MEMBER,
  ACTIVATE_MEMBER,
  GET_MEMBER_SAVINGS_REQUEST,
  GET_SAVINGS_REQUEST,
  APPROVE_CHANGE_REQUEST,
  DENY_CHANGE_REQUEST,
  UPLOAD_DOCUMENTS,
  DOWNLOAD_MEMBERS} from "./CONSTANTS";

const memberApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Change password
    saveMember: builder.mutation({
      query: (data) => ({
        url: CREATE_MEMBER,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Members"],
    }),

    // get coop members
    getCoopMembers: builder.query({
      query: ({coopId, search, page}) => ({
        url: `${GET_MEMBERS}?cooperative_id=${coopId}&page=${page || 1}&query=${search || ''}`,
        method: "GET",
      }),
      providesTags: ["Members"],
    }),
    // get inactive coop members
    getInactiveCoopMembers: builder.query({
      query: ({coopId, search, page}) => ({
        url: `${GET_MEMBERS}/get-deactivated-members?cooperative_id=${coopId}&page=${page || 1}&query=${search || ''}`,
        method: "GET",
      }),
      providesTags: ["Members"],
    }),
    // get single coop member
    getCoopMember: builder.query({
      query: ({coop_id, member_id}) => ({
        url: `${GET_MEMBER}?cooperative_id=${coop_id}&id=${member_id}`,
        method: "GET",
      }),
      providesTags: ["Members"],
    }),
    // get coop member dashboard data
    getMemberDashboardData: builder.query({
      query: (id) => ({
        url: `${MEMBERS_DASHBOARD}?cooperative_id=${id}`,
        method: "GET",
      }),
      providesTags: ["Members"],
    }),
    // import coop members
    importMembers: builder.mutation({
      query: (data) => ({
        url: IMPORT_MEMBERS,
        method: "POST",
        body: data,
        formData: true,
      }),
      invalidatesTags: ["Members"],
    }),
    // get transaction history
    getTransactionHistory: builder.query({
      query: ({coopId, page, id, download}) => ({
        url: `${MEMBER_TRANSACTION_HISTORY}?cooperative_id=${id}&coop_id=${coopId}&page=${page}&download=false`,
        method: 'GET',
      }),
      providesTags: ["TransactionOrder"],
    }),
    // edit member details
    editMemberDetails: builder.mutation({
      query: (data) => ({
        url: EDIT_MEMBER_DETAILS,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Members"],
    }),
    // upload member documents
    uploadDocument: builder.mutation({
      query: (data) => ({
        url: UPLOAD_DOCUMENTS,
        method: "POST",
        body: data,
        formData: true,
      }),
      invalidatesTags: ["Members"],
    }),
    // change member password
    changeMemberPassword: builder.mutation({
      query: (data) => ({
        url: CHANGE_MEMBER_PASSWORD,
        method: "POST",
        body: data
      }),
    }),
    // add new savings plan
    addNewSavingsPlan: builder.mutation({
      query: (data) => ({
        url: ADD_NEW_SAVINGS,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Savings"]
    }),
    // change savings amount
    changeSavingsAmount: builder.mutation({
      query: (data) => ({
        url: CHANGE_SAVINGS_AMOUNT,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Savings"]
    }),
    // get member savings
    getMemberSavings: builder.query({
      query: ({id, coop_id, page}) => ({
        url: `${GET_MEMBER_SAVINGS}?coop_id=${coop_id}&cooperative_id=${id}&page=${page}`,
        method: "GET",
      }),
      providesTags: ["Savings"]
    }),
    getMemberSavingsRequest: builder.query({
      query: ({coopId, id, page, role}) => {
        const url = (role === 'cooperative-member' || role === 'cooperative-executive') ? `${GET_MEMBER_SAVINGS_REQUEST}?coop_id=${coopId}&cooperative_id=${id}&page=${page}` :
          `${GET_SAVINGS_REQUEST}?cooperative_id=${id}&page=${page}`;
        return {
          url,
          method: "GET",
        }
      },
      providesTags: ["SavingsRequest"]
    }),
    approveChangeRequest: builder.mutation({
      query: ({data, coopId, requestId}) => ({
        url: `${APPROVE_CHANGE_REQUEST}?cooperative_id=${coopId}&request_id=${requestId}`,
        method: "POST",
        body: data,
      })
    }),
    denyChangeRequest: builder.mutation({
      query: ({data, coopId, requestId}) => ({
        url: `${DENY_CHANGE_REQUEST}?cooperative_id=${coopId}&request_id=${requestId}`,
        method: "POST",
        body: data,
      })
    }),
     // import savings
    importSavings: builder.mutation({
      query: (data) => ({
        url: IMPORT_SAVINGS,
        method: "POST",
        body: data,
        formData: true
      }),
      invalidatesTags: ["Savings"]
    }),
    // import loans
    importLoans: builder.mutation({
      query: (data) => ({
        url: IMPORT_LOANS,
        method: "POST",
        body: data,
        formData: true
      }),
      invalidatesTags: ["Loans"]
    }),
    // get loan list
    getLoanList: builder.query({
      query: ({coopId, page, start, end}) => ({
        url: `${GET_LOAN_LIST}?cooperative_id=${coopId}&page=${page}&start_date=${start}&end_date=${end}`,
        method: "GET",
      }),
      providesTags: ["Loans"]
    }),
    // deny loan
    denyLoan: builder.mutation({
      query: (loanId) => ({
        url: `${DENY_LOAN}?loan_id=${loanId}`,
        method: "GET",
      }),
      invalidatesTags: ["Loans"]
    }),
    // approve loan
    approveLoan: builder.mutation({
      query: (data) => ({
        url: APPROVE_LOAN,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Loans"]
    }),
    // apply for loan
    applyForLoan: builder.mutation({
      query: (data) => ({
        url: APPLY_FOR_LOAN,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Loans"]
    }),    
    // mark loan as incomplete
    markLoanAsIncomplete: builder.mutation({
      query: (loan_id) => ({
        url: `${MARK_LOAN_INCOMPLETE}?loan_id=${loan_id}`,
        method: "GET",
      }),
      invalidatesTags: ["Loans"]
    }),
    // mark loan as completed
    markLoanAsCompleted: builder.mutation({
      query: (loan_id) => ({
        url: `${MARK_LOAN_COMPLETED}?loan_id=${loan_id}`,
        method: "GET",
      }),
      invalidatesTags: ["Loans"]
    }),
    // GET LAON SURETY
    getLoanSurety: builder.query({
      query: (loan_id) => ({
        url: `${GET_LOAN_SURETY}?loan_id=${loan_id}`,
        method: "GET",
      }),
      providesTags: ["Loans"]
    }),
    // GET LAON SCHEDULE
    getLoanSchedule: builder.query({
      query: (loan_id) => ({
        url: `${GET_LOAN_SCHEDULE}?loan_id=${loan_id}`,
        method: "GET",
      }),
      providesTags: ["Loans"]
    }),
    // DOWNLOAD LAON FORM
    downloadLoanForm: builder.query({
      query: (loan_id) => ({
        url: `${DOWNLOAD_LOAN_FORM}?loan_id=${loan_id}`,
        method: "GET",
      }),
    }),
    // GET MEMBER LAON 
    getMemberLoans: builder.query({
      query: ({coopId, id}) => ({
        url: `${GET_MEMBER_LOANS}?cooperative_id=${coopId}&coop_id=${id}`,
        method: "GET",
      }),
      providesTags: ["Loans"]
    }),
    // GET ALL SAVINGS 
    getAllSavings: builder.query({
      query: ({coopId, start, end, page}) => ({
        url: `${GET_ALL_SAVINGS}?cooperative_id=${coopId}&start_date=${start}&end_date=${end}&page=${page}`,
        method: "GET",
      }),
      providesTags: ["TransactionOrder"]
    }),
    // GET ALL WITHDRAWALS 
    getAllWithdrawals: builder.query({
      query: ({coopId, start, end, page, search}) => ({
        url: `${GET_ALL_WITHDRAWALS}?cooperative_id=${coopId}&start_date=${start}&end_date=${end}&page=${page || 1}&query=${search || ''}`,
        method: "GET",
      }),
      providesTags: ["TransactionOrder"]
    }),
    // GET ALL DEPOSITS 
    getAllDeposits: builder.query({
      query: ({coopId, start, end, page, search}) => ({
        url: `${GET_ALL_DEPOSITS}?cooperative_id=${coopId}&start_date=${start}&end_date=${end}&page=${page || 1}&query=${search || ''}`,
        method: "GET",
      }),
      providesTags: ["TransactionOrder"]
    }),
    // DENY TRANSACTION ORDER
    denyTransactionOrder: builder.mutation({
      query: (data) => ({
        url: DENY_TRANSACTION_ORDER,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["TransactionOrder"]
    }),
    // APPROVE TRANSACTION ORDER
    approveTransactionOrder: builder.mutation({
      query: (data) => ({
        url: APPROVE_TRANSACTION_ORDER,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["TransactionOrder"]
    }),
    // CREATE TRANSACTION ORDER
    createTransactionOrder: builder.mutation({
      query: (data) => ({
        url: CREATE_TRANSACTION_ORDER,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["TransactionOrder"]
    }),
    downloadSample: builder.query({
      query: (coopId) => ({
        url: `${DOWNLOAD_SAMPLE_MEMBERS_IMPORT}?cooperative_id=${coopId}`,
        method: "GET",
      }),
    }),
    downloadMembers: builder.query({
      query: (coopId) => ({
        url: `${DOWNLOAD_MEMBERS}?cooperative_id=${coopId}`,
        method: "GET",
      }),
    }),
    getSuretyRequest: builder.query({
      query: ({coopId, id}) => ({
        url: `${GET_MEMBER_SURETY}?cooperative_id=${id}&coop_id=${coopId}`,
        method: "GET",
      }),
      providesTags: ["SuretyRequest"]
    }),
    approveSuretyRequest: builder.mutation({
      query: ({coopId, id}) => ({
        url: `${GET_APPROVE_SURETY}?cooperative_id=${coopId}&surety_id=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["SuretyRequest"]
    }),
    denySuretyRequest: builder.mutation({
      query: ({coopId, id}) => ({
        url: `${GET_DENY_SURETY}?cooperative_id=${coopId}&surety_id=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["SuretyRequest"]
    }),
    getOffsetMode: builder.query({
      query: () => ({
        url: GET_OFFSET_MODE,
        method: "GET",
      }),
    }),
    getOffsetType: builder.query({
      query: () => ({
        url: GET_OFFSET_TYPE,
        method: "GET",
      }),
    }),
    getOffsetMemberLoan: builder.query({
      query: ({coopId, memberId}) => ({
        url: `${GET_OFFSET_MEM_LOAN}?cooperative_id=${coopId}&coop_id=${memberId}`,
        method: "GET",
      }),
    }),
    getOffsetLoanDetail: builder.query({
      query: ({coopId, id}) => ({
        url: `${GET_LOAN_DETAILS}?cooperative_id=${coopId}&loan_id=${id}`,
        method: "GET",
      }),
    }),
    getOffsetHistory: builder.query({
      query: ({coopId, memberId}) => ({
        url: `${GET_LOAN_OFFSET}?cooperative_id=${coopId}&coop_id=${memberId}`,
        method: "GET",
      }),
      providesTags: ["LoanOffset"]
    }),
    submitLoanOffset: builder.mutation({
      query: (body) => ({
        url: LOAN_OFFSET,
        method: "POST",
        body
      }),
      invalidatesTags: ["LoanOffset"]
    }),
    // PENDING MEMBERS
    getPendingMembers: builder.query({
      query: (cooperative_id) => ({
        url: `${PENDING_MEMBERS}?cooperative_id=${cooperative_id}`,
        method: "GET",
      }),
      providesTags: ["PendingMembers"]
    }),
    getPendingMemberDetails: builder.query({
      query: ({cooperative_id, id}) => ({
        url: `${GET_PENDING_MEMBER}?cooperative_id=${cooperative_id}&id=${id}`,
        method: "GET",
      }),
      providesTags: ["PendingMembers"]
    }),
    // save pending member
    savePendingMember: builder.mutation({
      query: (data) => ({
        url: PENDING_MEMBERS,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["PendingMembers"]
    }),
    // approve pending member
    approvePendingMember: builder.mutation({
      query: (data) => ({
        url: APPROVE_PENDING_MEMBER,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["PendingMembers"]
    }),
    // deny a member
    denyMember: builder.query({
      query: ({coopId, memberId}) => ({
        url: `${DENY_PENDING_MEMBER}?member_id=${memberId}&cooperative_id=${coopId}`,
        method: "GET",
      }),
      invalidatesTags: ["PendingMembers"]
    }),
    // get coop details
    getCooperative: builder.query({
      query: (code) => ({
        url: `${GET_COOPERATIVE_DETAILS}?cooperative_code=${code}`,
        method: "GET",
      }),
    }),
    // get coop departments
    getCooperativeDepartments: builder.query({
      query: (code) => ({
        url: `${GET_COOPERATIVE_DEPARTMENTS}?cooperative_code=${code}`,
        method: "GET",
      }),
    }),
    uploadLoanForm: builder.mutation({
      query: (data) => ({
        url: UPLOAD_LOAN_FORM,
        method: "POST",
        body: data,
        formData: true
      }),
      invalidatesTags: ["UploadedForms"]
    }),
    getMemberUploadedForms: builder.query({
      query: ({coopId, memberId, page}) => ({
        url: `${GET_MEMBER_UPLOADED_FORMS}?coop_id=${memberId}&cooperative_id=${coopId}&page=${page}`,
        method: "GET",
      }),
      providesTags: ["UploadedForms"]
    }),
    getUploadedForms: builder.query({
      query: ({coopId, page}) => ({
        url: `${GET_UPLOADED_FORMS}?cooperative_id=${coopId}&page=${page}`,
        method: "GET",
      }),
      providesTags: ["UploadedForms"]
    }),
    getDownloadFormLink: builder.query({
      query: ({coopId, id}) => ({
        url: `${DOWNLOAD_UPLOADED_FORM}?cooperative_id=${coopId}&form_id=${id}`,
        method: "GET",
      }),
      providesTags: ["UploadedForms"]
    }),
    deactivateMember: builder.mutation({
      query: ({cooperative_id, coopId}) => ({
        url: `${DEACTIVATE_MEMBER}?cooperative_id=${cooperative_id}&coop_id=${coopId}`,
        method: 'GET'
      }),
      invalidatesTags: ["Members"]
    }),
    activateMember: builder.mutation({
      query: ({cooperative_id, coopId}) => ({
        url: `${ACTIVATE_MEMBER}?cooperative_id=${cooperative_id}&coop_id=${coopId}`,
        method: 'GET'
      }),
      invalidatesTags: ["Members"]
    }),
  }),
});

export const {
  useDeactivateMemberMutation,
  useActivateMemberMutation,
  useGetCooperativeQuery,
  useGetCooperativeDepartmentsQuery,
  useLazyGetDownloadFormLinkQuery,
  useGetMemberUploadedFormsQuery,
  useGetUploadedFormsQuery,
  useUploadLoanFormMutation,
  useGetPendingMemberDetailsQuery,
  useGetPendingMembersQuery,
  useLazyDenyMemberQuery,
  useSavePendingMemberMutation,
  useApprovePendingMemberMutation,
  useGetOffsetHistoryQuery,
  useSubmitLoanOffsetMutation,
  useGetOffsetLoanDetailQuery,
  useGetOffsetMemberLoanQuery,
  useGetOffsetModeQuery,
  useGetOffsetTypeQuery,
  useCreateTransactionOrderMutation,
  useApproveTransactionOrderMutation,
  useDenyTransactionOrderMutation,
  useLazyGetAllSavingsQuery,
  useLazyGetAllDepositsQuery,
  useLazyGetAllWithdrawalsQuery,
  useGetMemberLoansQuery,
  useApplyForLoanMutation,
  useMarkLoanAsCompletedMutation,
  useMarkLoanAsIncompleteMutation,
  useLazyGetLoanListQuery,
  useGetLoanScheduleQuery,
  useGetLoanSuretyQuery,
  useApproveLoanMutation,
  useDenyLoanMutation,
  useGetMemberSavingsQuery,
  useGetMemberSavingsRequestQuery,
  useImportLoansMutation,
  useLazyGetTransactionHistoryQuery,
  useEditMemberDetailsMutation,
  useChangeMemberPasswordMutation,
  useAddNewSavingsPlanMutation,
  useChangeSavingsAmountMutation,
  useSaveMemberMutation,
  useImportSavingsMutation,
  useGetCoopMemberQuery,
  useLazyGetCoopMembersQuery,
  useLazyGetInactiveCoopMembersQuery,
  useGetMemberDashboardDataQuery,
  useImportMembersMutation,
  useUploadDocumentMutation,
  useLazyDownloadSampleQuery,
  useLazyDownloadMembersQuery,
  useGetSuretyRequestQuery,
  useApproveSuretyRequestMutation,
  useDenySuretyRequestMutation,
  useApproveChangeRequestMutation,
  useDenyChangeRequestMutation,
} = memberApiSlice;
