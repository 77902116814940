import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import alertify from "alertifyjs";
import { useCreateProductCategoriesMutation, useUpdateProductCategoriesMutation } from "../../../../../services/e-shop.service";
import * as Yup from "yup";

// Validation Schema
export const FormSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the product category name'),
    status: Yup.number().oneOf([0, 1], 'Please select a valid status').required('Please select a product category status'),
    description: Yup.string().required('Please enter a product category description')
});

const CategoryForm = ({ cat, onCancel }) => {
    const [message, setMessage] = useState("");

    // API Mutations
    const [create, { isLoading, isSuccess, isError, data, error }] = useCreateProductCategoriesMutation();
    const [update, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError, data: updateData, error: updateError }] = useUpdateProductCategoriesMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FormSchema),
        mode: 'onBlur',
    });

    // Populate form if updating an existing category
    useEffect(() => {
        if (cat) {
            reset({
                name: cat.name || "",
                status: cat.status ?? "", 
                description: cat.description || "",
            });
        }
    }, [cat, reset]);

    const onSubmit = async (values) => {
        const payload = {
            category_id: cat?.id, 
            name: values.name,
            status: Number(values.status),
            description: values.description,
        };
    
        if (cat) {
            update(payload); 
        } else {
            create(payload);
        }
    };
    

    // Handle success & error messages
    useEffect(() => {
        if (isSuccess || isUpdateSuccess) {
            resetForm();
            setMessage(data?.message || updateData?.message || "Category saved successfully");
            alertify.success(data?.message || updateData?.message);
        }
        if (isError || isUpdateError) {
            setMessage(error?.data?.message || updateError?.data?.message || "An error occurred");
            alertify.error(error?.data?.message || updateError?.data?.message);
        }
    }, [isSuccess, isUpdateSuccess, isError, isUpdateError, data, updateData, error, updateError]);

    // Reset form function
    const resetForm = () => {
        reset({
            name: "",
            status: "",
            description: "",
        });
    };

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">{cat ? "Edit Category" : "Add Category"}</h3>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label>Name</label>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <input {...field} type="text" className="form-control" placeholder="Enter category name" />
                                    )}
                                />
                                {errors.name && <small className="text-danger">{errors.name.message}</small>}
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label>Status</label>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <select {...field} className="form-control">
                                            <option value="">Select</option>
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </select>
                                    )}
                                />
                                {errors.status && <small className="text-danger">{errors.status.message}</small>}
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label>Description</label>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea {...field} className="form-control" rows={4} placeholder="Enter category description"></textarea>
                                    )}
                                />
                                {errors.description && <small className="text-danger">{errors.description.message}</small>}
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-round btn-primary" disabled={isLoading || isUpdateLoading}>
                        {isLoading || isUpdateLoading ? "Saving..." : "Save"}
                    </button>
                    &nbsp;&nbsp;
                    <button type="button" className="btn btn-round btn-default" onClick={() => { resetForm(); onCancel(); }}>
                        Cancel
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CategoryForm;
