
import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "../custom-query/customQuery";

const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: customBaseQuery,
  refetchOnFocus: true,
  tagTypes: [
    "Users", "Roles", "Cooperatives", "StaffCategories", "ProductCategories", "LoanProductSetup", "JournalEntry", "UploadedForms",
    "Products", "CoaCategories", "CoaSubCategories", "COA", "Charges", "SavingProductSetup", "Members", "LoanOffset", "InvestmentTracker",
    "BankAccounts", "Departments", "Savings", "Loans", "TransactionOrder", "SuretyRequest", "AssetTypes", "Assets", "PendingMembers", "Voting",
    "BirthdayTemplates", "SavingsRequest", "E-Shop",
  ],
  endpoints: (builder) => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  keepUnusedDataFor: 50000,
});

export default apiSlice;
