export const levels = [
    {value: 1, label: 'Level 1'},
    {value: 2, label: 'Level 2'},
    {value: 3, label: 'Level 3'},
    {value: 4, label: 'Level 4'},
    {value: 5, label: 'Level 5'},
    {value: 6, label: 'Level 6'},
    {value: 7, label: 'Level 7'},
    {value: 8, label: 'Level 8'},
    {value: 9, label: 'Level 9'},
    {value: 10, label: 'Level 10'},
]
// auth/user
export const LOGIN = "/auth/login";
export const RESET_PASSWORD = "/auth/password-reset";
export const LOGOUT = "/auth/logout";

// UTILITY APIS
export const GET_BANKS = '/banks'
export const GET_CALC_METHODS = '/calculation-method'
export const GET_DASHBOARD_DATA = '/dashboard'
export const GET_COOP_DASHBOARD_DATA = '/cooperative/dashboard'
export const GET_DASHBOARD_TILE_DATA = '/dashboard/tile-data'
export const CONSOLIDATED_REPORT = '/consolidated-report'
export const GET_COUNTRIES = '/utilities/countries'
export const GET_STATES = '/utilities/state'
export const PAYMENT_MODE = '/payment-mode'
export const SEND_REG_EMAIL = '/utilities/send-registration-email'

//SYSTEM SETUP :: ROLES
export const GET_ROLES = "/roles/all"
export const SEARCH_ROLES = "/roles/search"
export const SAVE_ROLES = "/roles"
export const UPDATE_ROLES = "/roles/update"
export const DELETE_ROLES = "/roles"

//SYSTEM SETUP :: STAFF CATEGORIES
export const GET_STAFF_CATEGORIES = "/staff-category"
export const SAVE_STAFF_CATEGORY = "/staff-category"
export const UPDATE_STAFF_CATEGORY = "/staff-category/update"
export const DELETE_STAFF_CATEGORY = "/staff-category"

//SYSTEM SETUP :: ADMIN USERS
export const GET_USERS = "/user/calicon"
export const SAVE_USER = "/user/calicon"
export const UPDATE_USER = "/user/calicon/update"
export const DELETE_USER = "/user/calicon"
export const GET_USER = "/user/calicon/single";


//COOP SETUP :: PRODUCT CATEGORIES
export const GET_PRODUCT_CATEGORIES = "/product/category"
export const GET_COOP_PRODUCT_CATEGORIES = "/cooperative/product/category"
export const SAVE_PRODUCT_CATEGORY = "/product/category"
export const SAVE_COOP_PRODUCT_CATEGORY = "/cooperative/product/category"
export const UPDATE_PRODUCT_CATEGORY = "/product/category/update"
export const UPDATE_COOP_PRODUCT_CATEGORY = "/cooperative/product/category/update"
export const DELETE_PRODUCT_CATEGORY = "/product/category"
export const DELETE_COOP_PRODUCT_CATEGORY = "/cooperative/product/category"

//COOP SETUP :: PRODUCTS
export const GET_PRODUCTS = "/product"
export const GET_COOP_PRODUCTS = "/cooperative/product"
export const GET_PRODUCTS_BY_CAT = "/product/by-category"
export const GET_COOP_PRODUCTS_BY_CAT = "/cooperative/product/by-category"
export const SAVE_PRODUCT = "/product"
export const SAVE_COOP_PRODUCT = "/cooperative/product"
export const UPDATE_PRODUCT= "/product/update"
export const UPDATE_COOP_PRODUCT= "/cooperative/product/update"
export const DELETE_PRODUCT = "/product"
export const DELETE_COOP_PRODUCT = "/cooperative/product"

//COOP SETUP :: COOPERATIVES
export const GET_ALL_COOPERATIVES = "/cooperative/all"
export const GET_COOPERATIVES_PAGE = "/cooperative"
export const SAVE_COOPERATIVE = "/cooperative"
export const UPDATE_COOPERATIVE = "/cooperative/update"
export const DELETE_COOPERATIVE = "/cooperative"

//COOP SETUP :: COA Category
export const GET_COA_CATEGORIES = "/coa/categories"
export const GET_COOP_COA_CATEGORIES = "/cooperative/coa/categories"
export const SAVE_COA_CATEGORY = "/coa/categories"
export const SAVE_COOP_COA_CATEGORY = "/cooperative/coa/categories"
export const UPDATE_COA_CATEGORY = "/coa/categories/update"
export const UPDATE_COOP_COA_CATEGORY = "/cooperative/coa/categories/update"
export const DELETE_COA_CATEGORY = "/coa/categories"
export const DELETE_COOP_COA_CATEGORY = "/cooperative/coa/categories"

//COOP SETUP :: COA SubCategory
export const GET_COA_SUBCATEGORIES = "/coa/sub_categories"
export const GET_COOP_COA_SUBCATEGORIES = "/cooperative/coa/sub_categories"
export const GET_COA_SUBCATS_BY_CATEGORY = "/coa/sub_categories/category"
export const GET_COOP_COA_SUBCATS_BY_CATEGORY = "/cooperative/coa/sub_categories/category"
export const SAVE_COA_SUBCATEGORY = "/coa/sub_categories"
export const SAVE_COOP_COA_SUBCATEGORY = "/cooperative/coa/sub_categories"
export const UPDATE_COA_SUBCATEGORY = "/coa/sub_categories/update"
export const UPDATE_COOP_COA_SUBCATEGORY = "/cooperative/coa/sub_categories/update"
export const DELETE_COA_SUBCATEGORY = "/coa/sub_categories"
export const DELETE_COOP_COA_SUBCATEGORY = "/cooperative/coa/sub_categories"

//COOP SETUP :: COA
export const GET_ALL_COA = "/coa"
export const GET_ALL_COOP_COA = "/cooperative/coa"
export const GET_COA_BY_SUBCAT = "/coa/subcategory"
export const GET_COOP_COA_BY_SUBCAT = "/cooperative/coa/subcategory"
export const GENERATE_GL_CODE = "/coa/generate-code"
export const SAVE_COA = "/coa"
export const SAVE_COOP_COA = "/cooperative/coa"
export const UPDATE_COA= "/coa/update"
export const UPDATE_COOP_COA= "/cooperative/coa/update"
export const DELETE_COA = "/coa"
export const DELETE_COOP_COA = "/cooperative/coa"

//COOP SETUP :: CHARGES
export const GET_CHARGES = "/charge"
export const GET_COOP_CHARGES = "/cooperative/charge"
export const SAVE_CHARGE = "/charge"
export const SAVE_COOP_CHARGE = "/cooperative/charge"
export const UPDATE_CHARGE = "/charge/update"
export const UPDATE_COOP_CHARGE = "/cooperative/charge/update"
export const DELETE_CHARGE = "/charge"
export const DELETE_COOP_CHARGE = "/cooperative/charge"

//COOP SETUP :: BANK ACCOUNTS
export const GET_BANK_ACCOUNTS = "/cooperative/bank-account"
export const SAVE_BANK_ACCOUNT = "/cooperative/bank-account"
export const UPDATE_BANK_ACCOUNT = "/cooperative/bank-account/update"
export const DELETE_BANK_ACCOUNT = "/cooperative/bank-account"

//COOP SETUP :: DEPARTMENT SETUP
export const GET_ALL_DEPARTMENTS = "/cooperative/department"
export const SAVE_DEPARTMENT = "/cooperative/department"
export const UPDATE_DEPARTMENT = "/cooperative/department/update"
export const DELETE_DEPARTMENT = "/cooperative/department/delete"


//COOP SETUP :: SAVINGS
export const GET_ALL_SAVINGS_PROD = "/product-setup/savings"
export const GET_ALL_COOP_SAVINGS = "/cooperative/product-setup/savings"
export const GET_SINGLE_SAVINGS = "/product-setup/savings/single"
export const GET_SAVINGS_PROD_ID = "/product-setup/savings/product"
export const GET_COOP_SAVINGS_PROD_ID = "/cooperative/product-setup/savings/product"
export const SAVE_SAVINGS = "/product-setup/savings"
export const SAVE_COOP_SAVINGS = "/cooperative/product-setup/savings"
export const UPDATE_SAVINGS = "/product-setup/savings/update"
export const UPDATE_COOP_SAVINGS = "/cooperative/product-setup/savings/update"
export const DELETE_SAVINGS = "/product-setup/savings"
export const DELETE_COOP_SAVINGS = "/cooperative/product-setup/savings"

//COOP SETUP :: LOANS
export const GET_ALL_LOANS_PROD = "/product-setup/loans"
export const GET_ALL_COOP_LOANS = "/cooperative/product-setup/loans"
export const GET_SINGLE_LOAN = "/product-setup/loans/single"
export const GET_LOANS_PROD_ID = "/product-setup/loans/product"
export const GET_COOP_LOANS_PROD_ID = "/cooperative/product-setup/loans/product"
export const SAVE_LOANS = "/product-setup/loans"
export const SAVE_COOP_LOANS = "/cooperative/product-setup/loans"
export const UPDATE_LOANS = "/product-setup/loans/update"
export const UPDATE_COOP_LOANS = "/cooperative/product-setup/loans/update"
export const DELETE_LOANS = "/product-setup/loans"
export const DELETE_COOP_LOANS = "/cooperative/product-setup/loans"


//COOP:: MEMBERS
export const CREATE_MEMBER = "/cooperative/member"
export const GET_MEMBERS = "/cooperative/member"
export const DOWNLOAD_MEMBERS = "cooperative/member/download-report"
export const GET_MEMBER = "/cooperative/member/single"
export const MEMBERS_DASHBOARD = "/cooperative/member/dashboard"
export const IMPORT_MEMBERS = "/cooperative/member/import"
export const DOWNLOAD_SAMPLE_MEMBERS_IMPORT = "cooperative/member/download-sample"
export const ACTIVATE_MEMBER = "cooperative/member/activate"
export const DEACTIVATE_MEMBER = "cooperative/member/deactivate"

// PENDING MEMBERS
export const PENDING_MEMBERS = "/cooperative/pending_members"
export const GET_PENDING_MEMBER = "/cooperative/pending_members/single"
export const APPROVE_PENDING_MEMBER = "/cooperative/pending_members/approve"
export const DENY_PENDING_MEMBER = "/cooperative/pending_members/deny"
export const GET_COOPERATIVE_DETAILS = "/cooperative/pending_members/get-cooperative"
export const GET_COOPERATIVE_DEPARTMENTS = "/cooperative/pending_members/get-department"

//COOP:: MEMBER DETAILS
export const MEMBER_TRANSACTION_HISTORY = "cooperative/member/details/transaction-history"
export const CHANGE_MEMBER_PASSWORD = "/cooperative/member/details/change-password"
export const EDIT_MEMBER_DETAILS = "/cooperative/member/details/edit"
export const GET_MEMBER_DASHBOARD = "/cooperative/member/single/dashboard"
export const UPLOAD_DOCUMENTS = "/cooperative/member/details/update-document"
// SAVINGS API
export const ADD_NEW_SAVINGS = "/cooperative/member/savings/add-plan"
export const CHANGE_SAVINGS_AMOUNT = "/cooperative/member/savings/change-amount"
export const GET_MEMBER_SAVINGS = "/cooperative/member/savings"
export const GET_MEMBER_SAVINGS_REQUEST = "/cooperative/member/savings/request/member"
export const GET_SAVINGS_REQUEST = "/cooperative/member/savings/request"
export const IMPORT_SAVINGS = "/cooperative/member/savings/import"
export const APPROVE_CHANGE_REQUEST = "/cooperative/member/savings/request/approve"
export const DENY_CHANGE_REQUEST = "/cooperative/member/savings/request/deny"
export const DOWNLOAD_SAVINGS_SAMPLE = "cooperative/member/savings/download-sample"
// LOANS API
export const IMPORT_LOANS = "/cooperative/loans/import"
export const GET_LOAN_LIST = "/cooperative/loans/list"
export const DELETE_LOAN = "/cooperative/member/loans/delete"
export const DENY_LOAN = "/cooperative/member/loans/deny"
export const APPROVE_LOAN = "/cooperative/member/loans/approve"
export const MARK_LOAN_INCOMPLETE = "/cooperative/member/loans/mark-as-incomplete"
export const MARK_LOAN_COMPLETED = "/cooperative/member/loans/mark-as-completed"
export const GET_LOAN_SURETY = "/cooperative/member/loans/view-surety"
export const GET_LOAN_SCHEDULE = "/cooperative/member/loans/loan-schedule"
export const DOWNLOAD_LOAN_FORM = "cooperative/member/loans/download-loan-form"
export const DOWNLOAD_LOAN_SAMPLE = "cooperative/loans/download-sample"
export const APPLY_FOR_LOAN = "/cooperative/member/loans/apply"
export const GET_MEMBER_LOANS = "/cooperative/member/loans"
export const GET_MEMBER_SURETY = "/cooperative/member/surety"
export const GET_APPROVE_SURETY = "/cooperative/member/surety/approve"
export const GET_DENY_SURETY = "/cooperative/member/surety/deny"
// TRANSACTION ORDER
export const GET_ALL_SAVINGS = "/cooperative/transaction-order/savings"
export const GET_ALL_WITHDRAWALS = "/cooperative/transaction-order/withdrawals"
export const GET_ALL_DEPOSITS = "/cooperative/transaction-order/deposits"
export const DENY_TRANSACTION_ORDER = "/cooperative/transaction-order/deny"
export const APPROVE_TRANSACTION_ORDER = "/cooperative/transaction-order/approve"
export const CREATE_TRANSACTION_ORDER = "/cooperative/transaction-order"

// ASSET TYPES
export const GET_ASSET_TYPES = "/cooperative/asset/type"
export const GET_ASSET_TYPE = "/cooperative/asset/type/single"
export const GET_ALL_ASSET_TYPES = "/cooperative/asset/type/all"
export const SAVE_ASSET_TYPE = "/cooperative/asset/type"
export const UPDATE_ASSET_TYPE = "/cooperative/asset/type/update"
export const DELETE_ASSET_TYPE = "/cooperative/asset/type"
// ASSETS
export const GET_ASSETS = "/cooperative/asset"
export const GET_ASSET = "/cooperative/asset/single"
export const SAVE_ASSET = "/cooperative/asset"
export const UPDATE_ASSET = "/cooperative/asset/update"
export const APPROVE_ASSET = "/cooperative/asset/approve"
export const DENY_ASSET = "/cooperative/asset/deny"
export const DELETE_ASSET = "/cooperative/asset"

// JOURNAL ENTRY
export const JOURNAL_ENTRY = "/cooperative/journal-entry";
export const UPDATE_JOURNAL_ENTRY = "/cooperative/journal-entry/update";
export const GET_JOURNAL_ENTRY = "/cooperative/journal-entry/by-code";

// LOAN OFFSET HISTORY
export const GET_OFFSET_MODE = "/cooperative/loan-offset/mode"
export const GET_OFFSET_TYPE = "/cooperative/loan-offset/type"
export const GET_OFFSET_MEM_LOAN = "/cooperative/loan-offset/member"
export const GET_LOAN_DETAILS = "/cooperative/loan-offset/loan-detail"
export const LOAN_OFFSET = "/cooperative/loan-offset"
export const GET_LOAN_OFFSET = "/cooperative/loan-offset/history"

// MONTHLY DEDUCTION
export const DOWNLOAD_MONTHLY_PAYROLL = "cooperative/monthly-deduction/download"
export const UPLOAD_MONTHLY_PAYROLL = "/cooperative/monthly-deduction/upload"

// REPORTS
export const INCOME_STATMENT = "cooperative/reports/income-statement";
export const MEMBER_BALANCE = "cooperative/reports/member-balance";
export const TRIAL_BALANCE = "cooperative/reports/trial-balance";
export const GET_ACCOUNT_STATEMENT = "cooperative/reports/get-account-statement";
export const GET_LEDGER_STATEMENT = "cooperative/reports/get-ledger-statement";
export const BALANCE_SHEET = "cooperative/reports/balance-sheet";
export const INVESTMENT_TRACKER = "/cooperative/reports/investment-tracker";

// LOAN UPLOAD
export const UPLOAD_LOAN_FORM = "/cooperative/loans/form/upload-loan-form";
export const DOWNLOAD_UPLOADED_FORM = "cooperative/loans/form/download-uploaded-form";
export const GET_MEMBER_UPLOADED_FORMS = "/cooperative/loans/form/member";
export const GET_UPLOADED_FORMS = "/cooperative/loans/form";

// VOTING APIs
export const CAST_VOTE = "/cooperative/voting/vote"
export const ELECTIONS = "/cooperative/voting/election"
export const GET_ELECTION = "/cooperative/voting/election/single"
export const UPDATE_ELECTION = "/cooperative/voting/election/update"
export const POSITIONS = "/cooperative/voting/position"
export const GET_POSITION = "/cooperative/voting/position/single"
export const GET_POSITIONS_BY_ELECTION = "/cooperative/voting/position/election"
export const UPDATE_POSITION = "/cooperative/voting/position/update"
export const CONTESTANTS = "/cooperative/voting/contestant"
export const GET_CONTESTANT = "/cooperative/voting/contestant/single"
export const GET_CONTESTANT_BY_POSITION = "/cooperative/voting/contestant/position"
export const UPDATE_CONTESTANT = "/cooperative/voting/contestant/update"
export const RESULT_BY_ELECTION = "/cooperative/voting/result/election"
export const RESULT_BY_POSITION = "/cooperative/voting/result/position"

//BIRTHDAY TEMPLATE APIs
export const BIRTHDAY_TEMPLATE = "/cooperative/templates"
export const SINGLE_BIRTHDAY_TEMPLATE = "/cooperative/templates/single"

//E-SHOP PRODUCTS APIs
export const GET_ALL_PRODUCT_CATEGORIES = "/e-shop/admin/product-category"
export const GET_PRODUCT_CATEGORY_BY_ID = "e-shop/admin/product-category"
export const CREATE_PRODUCT_CATEGORIES = "/e-shop/admin/product-category"
export const UPDATE_PRODUCT_CATEGORIES = "/e-shop/admin/product-category/update"
export const DELETE_PRODUCT_CATEGORIES = "/e-shop/admin/product-category"

